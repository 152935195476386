define("discourse/plugins/discourse-interstitials/discourse/helpers/add-target-to-markdown-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addTargetToMarkdownLinks;
  function addTargetToMarkdownLinks(text) {
    if (!text) {
      return text;
    }
    let pattern = /(?:__|[*#])|\[(.*?)\]\((.*?)\)/gim;
    return text.replace(pattern, '<a href="$2" target="_blank">$1</a>');
  }
});