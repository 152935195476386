define("discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-field-description", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if description}}
    <p class="control-description">
      {{description}}
    </p>
  {{/if}}
  */
  {
    "id": "OT4wWPLu",
    "block": "[[[41,[30,0,[\"description\"]],[[[1,\"  \"],[10,2],[14,0,\"control-description\"],[12],[1,\"\\n    \"],[41,[28,[32,0],[\"description\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"description\"],[[28,[32,1],[\"description\"],null]]]]],[[[1,[52,[30,1,[\"description\"]],[28,[30,1,[\"description\"]],null,null],[28,[32,2],[[30,0],\"description\",\"[\\\"The `description` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-field-description.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.description}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,3],[\"[[\\\"The `description` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-field-description.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.description}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"description\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/fields/di-field-description.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});