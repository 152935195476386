define("discourse/plugins/discourse-interstitials/discourse/routes/admin-plugins-discourse-interstitial", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "admin-plugins-discourse-interstitial"
  });
});