define("discourse/plugins/discourse-interstitials/discourse/routes/admin-plugins-discourse-interstitial-new", ["exports", "rsvp", "discourse/routes/discourse", "discourse/plugins/discourse-interstitials/discourse/mixins/interstitial-form-builder"], function (_exports, _rsvp, _discourse, _interstitialFormBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(_interstitialFormBuilder.default, {
    controllerName: "admin-plugins-discourse-interstitial-new",
    model() {
      return (0, _rsvp.hash)({
        interstitial: this.store.createRecord("discourse-interstitial-interstitial")
      });
    }
  });
});