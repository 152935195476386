define("discourse/plugins/discourse-interstitials/discourse/admin/models/discourse-interstitial-interstitial", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ATTRIBUTES = ["heading", "title", "content", "start_at", "end_at", "interstitial_type", "cta_buttons_alignment", "id", "groups", "cta_buttons", "users", "anonymous_users", "recurring_anonymous_users", "members", "categories"];
  const Interstitial = _rest.default.extend({
    updateProperties() {
      return {
        id: this.id,
        heading: this.heading,
        title: this.title,
        content: this.content,
        interstitial_type: this.interstitial_type,
        cta_buttons_alignment: this.cta_buttons_alignment,
        start_at: this.start_at,
        cta_buttons: this.cta_buttons,
        groups: this.groups,
        end_at: this.end_at,
        anonymous_users: this.anonymous_users,
        recurring_anonymous_users: this.recurring_anonymous_users,
        members: this.members,
        categories: this.categories
      };
    },
    createProperties() {
      return this.getProperties(ATTRIBUTES);
    }
  });
  var _default = _exports.default = Interstitial;
});