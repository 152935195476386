define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-date-time-field", ["exports", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field"], function (_exports, _object, _decorators, _diBaseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _diBaseField.default.extend(dt7948.p({
    convertToUniversalTime(date) {
      return date && this.set("field.metadata.value", moment(date).utc().format());
    },
    localTime(value) {
      return value && moment(value).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
  }, [["method", "convertToUniversalTime", [_object.action]], ["method", "localTime", [(0, _decorators.default)("field.metadata.value")]]]));
});