define("discourse/plugins/discourse-interstitials/discourse/components/cta-button", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "",
    ctaButtonClass(button) {
      if (this.siteSettings.allow_custom_cta_button_classes && button.color) {
        return button.color;
      }
      let link = button.link;
      if (link === "cancel") {
        return "btn-danger";
      }
      if (link === "snooze") {
        return "btn-secondary";
      }
      return "btn-primary";
    }
  }, [["method", "ctaButtonClass", [(0, _decorators.default)("button", "enableCustomClass")]]]));
});