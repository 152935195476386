define("discourse/plugins/discourse-interstitials/discourse/components/interstitials-form", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/discourse-interstitials/discourse/components/modal/interstitial-alert"], function (_exports, _component, _object, _service, _ajax, _ajaxError, _getUrl, _decorators, _interstitialAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    isUpdatingInterstitial: false,
    showVisibilityOptions() {
      return !this.siteSettings.login_required;
    },
    appearOnTopics() {
      return !this.siteSettings.appear_on_topics;
    },
    appearOnCategories() {
      return !this.siteSettings.appear_on_categories;
    },
    onChangePriority(id) {
      if (this.interstitialForm.priority !== id) {
        this.interstitialForm.set("priority", id);
      }
    },
    onChangeCtaAlignment(id) {
      if (this.interstitialForm.cta_buttons_alignment !== id) {
        this.interstitialForm.set("cta_buttons_alignment", id);
      }
    },
    onChangeType(id) {
      if (this.interstitialForm.interstitial_type !== id) {
        this.interstitialForm.set("interstitial_type", id);
      }
    },
    setImage(upload) {
      this.interstitialForm.set("image_upload_id", upload.id);
      this.interstitialForm.set("image_url", (0, _getUrl.default)(upload.url));
    },
    removeImage() {
      this.interstitialForm.set("image_upload_id", null);
      this.interstitialForm.set("image_url", null);
    },
    saveInterstitial() {
      this.setProperties({
        error: null,
        isUpdatingInterstitial: true
      });
      if (this.formType === "new") {
        return (0, _ajax.ajax)(`/admin/plugins/discourse-interstitial/interstitials`, {
          type: "POST",
          data: JSON.stringify({
            interstitial: this._formData(this.interstitialForm)
          }),
          dataType: "json",
          contentType: "application/json"
        }).then(response => {
          this.onComplete(response.interstitial);
        }).catch(e => this.set("error", (0, _ajaxError.extractError)(e))).finally(() => {
          this.set("isUpdatingInterstitial", false);
        });
      } else {
        return (0, _ajax.ajax)(`/admin/plugins/discourse-interstitial/interstitials/${this.interstitialForm.id}.json`, {
          type: "PUT",
          data: JSON.stringify({
            interstitial: this._formData(this.interstitialForm)
          }),
          dataType: "json",
          contentType: "application/json"
        }).then(response => {
          this.onComplete(response.interstitial);
        }).catch(e => this.set("error", (0, _ajaxError.extractError)(e))).finally(() => {
          this.set("isUpdatingInterstitial", false);
        });
      }
    },
    previewInterstitial(interstitial) {
      this.modal.show(_interstitialAlert.default, {
        model: interstitial
      });
    },
    _formData(form) {
      form.fields.forEach(field => {
        form[field["name"]] = field["metadata"]["value"];
      });
      ["start_at", "end_at"].forEach(dateField => {
        if (typeof form[dateField] === "undefined") {
          form[dateField] = "";
        }
      });
      delete form.fields;
      return form;
    }
  }, [["method", "showVisibilityOptions", [_decorators.default]], ["method", "appearOnTopics", [_decorators.default]], ["method", "appearOnCategories", [_decorators.default]], ["method", "onChangePriority", [_object.action]], ["method", "onChangeCtaAlignment", [_object.action]], ["method", "onChangeType", [_object.action]], ["method", "setImage", [_object.action]], ["method", "removeImage", [_object.action]], ["method", "saveInterstitial", [_object.action]], ["method", "previewInterstitial", [_object.action]]]));
});