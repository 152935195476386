define("discourse/plugins/discourse-interstitials/discourse/lib/build-custom-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildCustomStyles;
  function buildCustomStyles(interstitial) {
    let style = "";
    if (interstitial.interstitial_style) {
      //reset everything first - used for previewing.
      if (interstitial.interstitial_style.width) {
        let percentage = `${interstitial.interstitial_style.width}%`;
        style += `--modal-min-width: ${percentage}; --modal-max-width: ${percentage}; `;
      }
      if (interstitial.interstitial_style.height) {
        let percentage = `${interstitial.interstitial_style.height}%`;
        style += `--modal-min-height: ${percentage}; --modal-max-height: ${percentage}; `;
      }
      if (interstitial.interstitial_style.background_color) {
        style += `background-color: ${interstitial.interstitial_style.background_color}; `;
      }
    }
    return style;
  }
});