define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-category-field", ["exports", "@ember/object", "discourse/models/category", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field"], function (_exports, _object, _category, _diBaseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _diBaseField.default.extend(dt7948.p({
    categories: null,
    init() {
      this._super(...arguments);
      const ids = this.field?.metadata?.value || [];
      const categories = _category.default.list().filter(c => ids.includes(c.id));
      this.set("categories", categories);
    },
    setCategoryField(categoryList) {
      this.onChangeField(this.field, categoryList.map(c => c.id));
      this.set("categories", categoryList);
    }
  }, [["method", "setCategoryField", [_object.action]]]));
});