define("discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route="adminPlugins.discourse-interstitial"
    class="discourse-interstitial-title"
  >
    <h1 class="title">
      {{d-icon "chevron-left"}}
      {{i18n "discourse_interstitial.title"}}
    </h1>
  
    {{#if showNewInterstitial}}
      {{d-button
        label="discourse_interstitial.create"
        icon="plus"
        action=(action "newInterstitial")
        class="new-interstitial"
      }}
    {{/if}}
  </LinkTo>
  
  <hr />
  
  {{outlet}}
  */
  {
    "id": "HiJ/CZw5",
    "block": "[[[8,[39,0],[[24,0,\"discourse-interstitial-title\"]],[[\"@route\"],[\"adminPlugins.discourse-interstitial\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[14,0,\"title\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"chevron-left\"],null]],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_interstitial.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showNewInterstitial\"]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"label\",\"icon\",\"action\",\"class\"],[\"discourse_interstitial.create\",\"plus\",[28,[37,5],[[30,0],\"newInterstitial\"],null],\"new-interstitial\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\\n\"],[10,\"hr\"],[12],[13],[1,\"\\n\\n\"],[46,[28,[37,7],null,null],null,null,null],[1,[28,[32,0],[\"[[\\\"The `showNewInterstitial` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showNewInterstitial}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"link-to\",\"d-icon\",\"i18n\",\"if\",\"d-button\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});