define("discourse/plugins/discourse-interstitials/discourse/admin/adapters/discourse-interstitial-adapter", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    basePath() {
      return "/admin/plugins/discourse-interstitial/";
    },
    pathFor() {
      return this._super(...arguments).replace("_", "-") + ".json";
    }
  });
});