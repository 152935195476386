define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-group-field", ["exports", "@ember/object", "discourse/models/group", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field"], function (_exports, _object, _group, _diBaseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _diBaseField.default.extend(dt7948.p({
    allGroups: null,
    init() {
      this._super(...arguments);
      _group.default.findAll().then(groups => {
        groups.forEach(g => g.name = g.full_name || g.name);
        this.set("allGroups", groups);
      });
    },
    setGroupField(groupIds) {
      this.onChangeField(this.field, groupIds);
    }
  }, [["method", "setGroupField", [_object.action]]]));
});