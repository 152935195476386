define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    interstitial: (0, _computed.reads)("model.interstitial"),
    refreshPage() {
      this.send("refreshRoute");
    },
    onChangeField(field, identifier, value) {
      (0, _object.set)(field, `metadata.${identifier}`, value);
    }
  }, [["method", "refreshPage", [_object.action]], ["method", "onChangeField", [_object.action]]]));
});