define("discourse/plugins/discourse-interstitials/discourse/components/fields/di-user-field", ["exports", "@ember/object", "discourse/plugins/discourse-interstitials/discourse/components/fields/di-base-field"], function (_exports, _object, _diBaseField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _diBaseField.default.extend(dt7948.p({
    onChangeUsername(usernames) {
      this.onChangeField(this.field, usernames);
    }
  }, [["method", "onChangeUsername", [_object.action]]]));
});