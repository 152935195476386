define("discourse/plugins/discourse-interstitials/discourse/templates/components/modal-content", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if isHtml}}
    {{html-safe @content}}
  {{else}}
    {{cook-text rawText=@content}}
  {{/if}}
  */
  {
    "id": "8fIEQ13W",
    "block": "[[[41,[30,0,[\"isHtml\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"rawText\"],[[30,1]]]]],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `isHtml` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/modal-content.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.isHtml}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@content\"],false,[\"if\",\"html-safe\",\"cook-text\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/modal-content.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});