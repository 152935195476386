define("discourse/plugins/discourse-interstitials/discourse/routes/admin-plugins-discourse-interstitial-index", ["exports", "@ember/object", "discourse/models/group", "discourse/routes/discourse"], function (_exports, _object, _group, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend(dt7948.p({
    controllerName: "admin-plugins-discourse-interstitial-index",
    model() {
      return this.store.findAll("discourse-interstitial-interstitial");
    },
    triggerRefresh() {
      this.refresh();
    },
    setupController(controller, model) {
      _group.default.findAll().then(groups => {
        controller.setProperties({
          allGroups: groups
        });
      });
      controller.setProperties({
        model,
        allGroups: []
      });
    }
  }, [["method", "triggerRefresh", [_object.action]]]));
});