define("discourse/plugins/discourse-interstitials/discourse/admin/adapters/discourse-interstitial-interstitial", ["exports", "discourse/plugins/discourse-interstitials/discourse/admin/adapters/discourse-interstitial-adapter"], function (_exports, _discourseInterstitialAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseInterstitialInterstitialAdapter extends _discourseInterstitialAdapter.default {
    jsonMode = true;
    apiNameFor() {
      return "interstitial";
    }
  }
  _exports.default = DiscourseInterstitialInterstitialAdapter;
});