define("discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{interstitials-form
    formType="new"
    interstitialForm=interstitialForm
    onComplete=onCreate
  }}
  */
  {
    "id": "EzxjP1o7",
    "block": "[[[1,[28,[35,0],null,[[\"formType\",\"interstitialForm\",\"onComplete\"],[\"new\",[30,0,[\"interstitialForm\"]],[30,0,[\"onCreate\"]]]]]],[1,[28,[32,0],[\"[[\\\"The `interstitialForm` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.interstitialForm}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `onCreate` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.onCreate}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"interstitials-form\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});