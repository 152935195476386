define("discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    class=ctaButtonClass
    translatedLabel=button.title
    action=this.action
    disabled=saving
  }}
  */
  {
    "id": "TJWwaDaj",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"translatedLabel\",\"action\",\"disabled\"],[[30,0,[\"ctaButtonClass\"]],[30,0,[\"button\",\"title\"]],[30,0,[\"action\"]],[30,0,[\"saving\"]]]]]],[1,[28,[32,0],[\"[[\\\"The `ctaButtonClass` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.ctaButtonClass}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `button` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.button}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `saving` property path was used in the `discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.saving}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});